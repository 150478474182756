import axios from "axios";
import cookie from "js-cookie";
import qs from "querystring";
import DefaultResponseHandler from "./ResponseHandler";
import {
  browserName,
  fullBrowserVersion,
  osVersion,
  osName,
} from "react-device-detect";

const TOKEN_KEY = "customerToken";


const baseURL = process.env.REACT_APP_API_DOMAIN;
// const  baseURL = 'https://api.bennybutton.cloud/';
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  // "Set-Cookie": process.env.REACT_APP_API_COOKIE,
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true
};

const apiInstance = axios.create({
  baseURL,
  headers,
});

const apiWithKey = axios.create({
  baseURL,
  headers: {
    ...headers,
    "BB-API-ACCESS-KEY": process.env.REACT_APP_API_ACCESS_KEY,
    //  "BB-API-ACCESS-KEY": 'FNQSWFG4H18LBB2ULPROD',
  },
});

const apiController = "/v1/";
const API_LOGIN = () => `${apiController}session/login`;
const API_SIGNUP = () => `${apiController}session/signup`;
const API_RESET_PASSWORD = () => `${apiController}session/reset-password`;
const API_USER = () => `${apiController}user`;
const API_CHANGE_PASSWORD = () => `${apiController}session/change-password`;
const API_CHANGE_EMAIL = () => `${apiController}session/update-email`;
const API_COUNTRIES = () =>
  `${apiController}checkpoint-admin/all-iso-countries`;
const API_ORGANISATIONS = () =>
  `${apiController}checkpoint-admin/all-organisations`;

const API_ORGANISATION = (tag) => `${apiController}organisation/${tag}`;

const API_SINGLE_ORGANISATION = (tag) =>
  `${apiController}checkpoint-admin/organisation/${tag}`;

const API_ORGANISATION_TIERS = (tag) =>
  `${apiController}checkpoint-admin/organisation-tiers/${tag}`;

const API_SEND_OS_INFO = () => `${apiController}session/audit`;

//modular profiler
const API_GET_TYPEFORM_RESPONSE = (id) => `${apiController}modular-profiler/typeform-response-weighted/${id}`;

const API_GET_MULTI_TYPEFORM_RESPONSE = (id) => `${apiController}modular-profiler/typeform-responses-weighted?typeform-tokens=${id}`;

const API_GET_RECENT_RESPONSE = () => `${apiController}modular-profiler/recent-response-token-for-each-factor`;

const API_GET_FACTOR_RESPONSE = (type, limit) => `${apiController}modular-profiler/recent-responses?limit=${limit}&focus=${type}`;

const API_GET_USER_INFO = () => `${apiController}modular-profiler/user-info`;

const API_SET_RESOURCE_CLICKED = () => `${apiController}resource`;

const API_GET_WORK_LOCATIONS = (tag) => `${apiController}checkpoint-admin/work-locations/${tag}`;


let responseHandler;
const defaultResponseHandler = new DefaultResponseHandler();

/**
 * Utilities
 */

export const getAuthToken = () => cookie.get(TOKEN_KEY);

const setAuthorizationHeader = (token) => {
  if (!apiInstance.defaults.headers) {
    apiInstance.defaults.headers = {};
  }
  if (token) {
    apiInstance.defaults.headers.Authorization = `Bearer ${token}`;
    cookie.set(TOKEN_KEY, token, { expires: 2 });
  }
};

const removeAuthorizationHeader = () => {
  if (apiInstance.defaults.headers) {
    delete apiInstance.defaults.headers["Authorization"];
    cookie.remove(TOKEN_KEY);
  }
};

const setTemporaryResponseHandler = (handler) => {
  responseHandler = handler;
};

const executeRequest = (promise) => {
  return new Promise((resolve, reject) => {
    const handler =
      responseHandler && typeof responseHandler === "function"
        ? responseHandler
        : defaultResponseHandler;
    promise
      .then((response) => {
        handler.handleSuccess(response, resolve, reject);
        responseHandler = undefined;
      })
      .catch((error) => {
        console.log({ error });

        handler.handleError(error, reject);
        responseHandler = undefined;

        if (error?.response?.status === 401) {
          removeAuthorizationHeader();
        }
      });
  });
};

const doGetRequest = (url, params, config) => {
  const api = config?.withKey ? apiWithKey : apiInstance;
  let tierParam = {};
  let tierKey = "";
  if (params) {
    for (const i in params) {
      if (i.includes("tier")) {
        tierParam[i] = params[i];
        tierKey = i;
        delete params[i];
      }
    }
  }
  url += `${qs.stringify(params)}`;
  if (tierKey) {
    for (const i in tierParam) {
      url = `${url}&${i}=${tierParam[i]}`;
      break;
    }
  }
  return executeRequest(api.get(url, config));
};

const doPostRequest = (url, payload, config) => {
  const api = config?.withKey ? apiWithKey : apiInstance;
  return executeRequest(api.post(url, payload, config));
};

const doPutRequest = (url, payload, { withKey, signupKey } = {}) => {
  const api = withKey ? apiWithKey : apiInstance;
  const headers = signupKey ? { "BB-ORG-SIGNUP-KEY": signupKey } : {};

  return executeRequest(api.put(url, payload, { headers }));
};

// const doDeleteRequest = (url, payload, config) => {
//   return executeRequest(apiInstance.delete(url, payload, config));
// };

/**
 * Utilities
 */

/**
 * Authentication
 */
const login = (email, password) => {
  return doPostRequest(API_LOGIN(), {
    email: email,
    password: password,
  });
};

const signup = ({ signupKey, ...payload }) => {
  return doPutRequest(API_SIGNUP(), payload, {
    withKey: true,
    signupKey,
  });
};

const resetPassword = (email) => doPostRequest(API_RESET_PASSWORD(), { email });
/**
 * Authentication
 */

/**
 * Authenicated
 */
const getUser = ({ userToken }) => {
  if (userToken) {
    setAuthorizationHeader(userToken);
  }

  return doGetRequest(API_USER());
};

const changePassword = ({ password, existingPassword, email }) => {
  return doPostRequest(API_CHANGE_PASSWORD(), {
    email,
    password,
    oldPassword: existingPassword,
  });
};

export const changeEmail = ({ email }) => {
  return doPostRequest(API_CHANGE_EMAIL(), {
    email,
  });
};

const sendOSInfo = async (eventType) => {
  let latitude = 0;
  let longitude = 0;
  const coords = localStorage.getItem("coords");
  if (coords) {
    const data = JSON.parse(coords);
    latitude = data.latitude;
    longitude = data.longitude;
  }

  const param = {
    fcmToken: null,
    uniqueDeviceIdentification: "Modular Profiler Desktop",
    deviceMsisdn: "",
    eventType,
    browserInformation: `${browserName} ${fullBrowserVersion}`,
    deviceOperatingSystem: `${osName} ${osVersion}`,
    latitude,
    longitude,
  };
  doPostRequest(API_SEND_OS_INFO(), param);
};
export const getOrganisations = () =>
  doGetRequest(API_ORGANISATIONS(), {}, { withKey: true });

export const getOrganisationTiers = (tag) =>
  doGetRequest(API_ORGANISATION_TIERS(tag), {}, { withKey: true });

export const getOrganisation = (tag) => {
  doGetRequest(API_ORGANISATION(tag), {}, { withKey: true });
};

export const getSingleOrganisation = (tag) =>
  doGetRequest(API_SINGLE_ORGANISATION(tag), {}, { withKey: true });

export const updateAccount = (data) => doPutRequest(API_USER(), data);

export const getAllCountries = () =>
  doGetRequest(API_COUNTRIES(), null, { withKey: true });


//modular profiler
export const getTypeformResponse = (id) => doGetRequest(API_GET_TYPEFORM_RESPONSE(id));

export const getRecentResponse = (id) => doGetRequest(API_GET_RECENT_RESPONSE(id));

export const getFactorResponse = (type, limit) => doGetRequest(API_GET_FACTOR_RESPONSE(type, limit));

export const getMultiTypeformResponse = (id) => doGetRequest(API_GET_MULTI_TYPEFORM_RESPONSE(id));

export const getUserInfo = () => doGetRequest(API_GET_USER_INFO());

export const getWorkLocation = (tag) => doGetRequest(API_GET_WORK_LOCATIONS(tag), {}, { withKey: true });

export const setResourceClicked = (data) =>
  doPostRequest(API_SET_RESOURCE_CLICKED(), data);

/**
 * Authenicated
 */

const exported = {
  apiInstance,
  setTemporaryResponseHandler,
  setAuthorizationHeader,
  removeAuthorizationHeader,
  login,
  getUser,
  signup,
  resetPassword,
  changePassword,
  sendOSInfo,
  getTypeformResponse,
  getRecentResponse,
  getFactorResponse,
  getMultiTypeformResponse,
  getUserInfo,
  getWorkLocation
};

export default exported;
