import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Loading from "./components/Loading";
import { useAuth, UserProvider } from "./contexts/UserContext";

const Home = lazy(() => import("./pages/home"));
const Signin = lazy(() => import("./pages/sign-in"));
const Signup = lazy(() => import("./pages/sign-up"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const Account = lazy(() => import("./pages/account"));
const NewPassword = lazy(() => import("./pages/new-password"));
const NewEmail = lazy(() => import("./pages/new-email"));
const Typeform = lazy(() => import("./pages/type-form"));
const Factor = lazy(() => import("./pages/factor"));
const Wellbeing = lazy(() => import("./pages/wellbeing"));
const Terms = lazy(() => import("./pages/terms"));
const Articles = lazy(() => import("./pages/articles"));
const CheckAuth = lazy(() => import("./pages/check-auth"));
const SupportServices = lazy(() => import("./pages/support-services"));
const StartScreen = lazy(() => import("./pages/start"));

const PublicRoute = (props) => {
  const { userToken } = useAuth();

  if (userToken && ["/sign-in", "/sign-up"].includes(props.path)) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

const ProtectedRoute = ({ redirectPath = "/sign-in", children, ...rest }) => {
  const { userToken } = useAuth();

  if (!userToken) {
    return <Redirect to={redirectPath} />;
  }

  return <Route {...rest}>{children}</Route>;
};

const IndexRoute = ({ children, ...props }) => {
  const { userToken, user } = useAuth();

  if (!userToken) {
    return <Redirect to="/sign-in" />;
  }

  if (!user) {
    return <Loading isFullScreen />;
  }

  return <Route {...props}>{children}</Route>;
};

function App() {
  return (
    <Suspense fallback={<Loading isFullScreen />}>
      <Router>
        <UserProvider>
          <Switch>
            <IndexRoute path="/" exact>
              <StartScreen />
            </IndexRoute>
            <ProtectedRoute path="/profiler">
              <Home />
            </ProtectedRoute>
            <PublicRoute path="/check-auth">
              <CheckAuth />
            </PublicRoute>
            <PublicRoute path="/sign-in">
              <Signin />
            </PublicRoute>
            <PublicRoute path="/sign-up">
              <Signup />
            </PublicRoute>
            <PublicRoute path="/reset-password">
              <ResetPassword />
            </PublicRoute>
            <ProtectedRoute path="/account">
              <Account isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/new-password">
              <NewPassword isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/new-email">
              <NewEmail isProtected />
            </ProtectedRoute>
            <ProtectedRoute path="/typeform">
              <Typeform isProtected />
            </ProtectedRoute>
            <IndexRoute path="/articles">
              <Articles />
            </IndexRoute>
            <IndexRoute path="/factor">
              <Factor isProtected />
            </IndexRoute>
            <IndexRoute path="/wellbeing-snapshot">
              <Wellbeing isProtected />
            </IndexRoute>
            <PublicRoute path="terms">
              <Terms />
            </PublicRoute>
            <IndexRoute path="/support-services">
              <SupportServices isProtected />
            </IndexRoute>
          </Switch>
        </UserProvider>
      </Router>
    </Suspense>
  );
}

export default App;
